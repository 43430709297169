<h1 mat-dialog-title>Validation Errors for {{data.file.fileName}}</h1>
<mat-dialog-content>
  <ul>
    <li *ngFor="let error of data.file.errors">
      <app-value-set-name-and-scope [scope]="error.record.Scope" [name]="error.record.ValueSet">
      </app-value-set-name-and-scope>: {{error.message}}
    </li>
  </ul>
  <p *ngIf="!canFix">Please fix these errors and re-upload the file</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button *ngIf="canFix" [mat-dialog-close]="true" cdkFocusInitia>Fix</button>
  <button mat-button [mat-dialog-close]="false">Re-upload</button>
</mat-dialog-actions>
