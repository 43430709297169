import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // don't need an API key to fetch the config
    if (request.url.endsWith('config.json')) {
      return next.handle(request);
    }

    if (!this.authService.isAuthenticated) {
      throw new Error('Not authenticated');
    }
    const authRequest = request.clone({
      headers: request.headers.set('x-api-key', this.authService.apiKey),
    });
    return next.handle(authRequest).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.authService.onSignOut();
          this.router.navigate(['/login'], {queryParams: {status: error.status }});
          return EMPTY;
        }

        throw error;
      })
    );
  }
}
