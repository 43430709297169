import { APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasicCodingTableComponent } from './edit/code-system-explorer/basic-coding-table/basic-coding-table.component';
import { HomeComponent } from './home/home.component';
import { EditComponent } from './edit/edit.component';
import { UploadComponent } from './upload/upload.component';
import { NewFileComponent } from './new-file/new-file.component';
import { ValueSetChooserComponent } from './edit/value-set-chooser/value-set-chooser.component';
import { AddValueSetComponent } from './edit/value-set-chooser/add-value-set/add-value-set.component';
import { ValueSetMembershipComponent } from './edit/value-set-membership/value-set-membership.component';
import { CodeSystemExplorerComponent } from './edit/code-system-explorer/code-system-explorer.component';

import { httpInterceptorProviders } from './http';

import { loadConfig } from './app-initializer';
import { ConfigService } from './config.service';
import { AfterSaveDialogComponent } from './edit/after-save-dialog/after-save-dialog.component';
import { ValueSetNameAndScopeComponent } from './edit/value-set-name-and-scope/value-set-name-and-scope.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ValueSetPropertiesDialogComponent } from './edit/value-set-properties-dialog/value-set-properties-dialog.component';
import { ValidationErrorsDialogComponent } from './edit/validation-errors-dialog/validation-errors-dialog.component';
import { FixErrorsComponent } from './fix-errors/fix-errors.component';
import { CodeSystemSelectorComponent } from './code-system-selector/code-system-selector.component';

import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EditComponent,
    UploadComponent,
    NewFileComponent,
    ValueSetChooserComponent,
    AddValueSetComponent,
    ValueSetMembershipComponent,
    CodeSystemExplorerComponent,
    BasicCodingTableComponent,
    AfterSaveDialogComponent,
    ValueSetNameAndScopeComponent,
    ConfirmDialogComponent,
    ValueSetPropertiesDialogComponent,
    ValidationErrorsDialogComponent,
    FixErrorsComponent,
    CodeSystemSelectorComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    MatToolbarModule,
    MaterialFileInputModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      multi: true,
      deps: [HttpClient, ConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
