<form>
  <mat-form-field appearance="fill">
    <mat-label>Upload Value Sets CSV</mat-label>
    <ngx-mat-file-input [accept]="'.csv'" (change)="handleUpload($event.target.files)"></ngx-mat-file-input>
  </mat-form-field>
</form>
<div class="progress" *ngIf="progress | async">
  Validating with Rosetta
  <mat-progress-bar mode="determinate" [value]="progress | async"></mat-progress-bar>
</div>
<div>
  <h3>CSV Schema</h3>
  <dl>
    <dt>Scope</dt>
    <dd>The TermSubset scope for this value set</dd>

    <dt>ValueSet</dt>
    <dd>The TermSubset name for this value set</dd>

    <dt>System</dt>
    <dd>
      The CodeSystem id for this term. Valid values are:
      <ul>
        <li>LOINC</li>
        <Li>SNOMED</Li>
      </ul>
    </dd>

    <dt>Domain</dt>
    <dd>
      The domain for this term. This is often the term family in HIEBus, but some concepts with many term families are
      rolled up into a single domain, for example Medications. <strong>Leave this blank for codes from reference systems
        like RxNorm or LOINC. </strong> Only set the domain for CareEvolution or FhirCode terms.
    </dd>

    <dt>Code</dt>
    <dd>The code for this term. ICD-9 and ICD-10 codes can be dotted or not.</dd>

    <dt>Name</dt>
    <dd>The display name for this term. This is used for human-reading this file only. Rosetta will use the canonical
      name for the code.</dd>

    <dt>Source</dt>
    <dd>An optional field for notes about where this membership came from, useful for cobbling to gether info from
      multiple sources</dd>

    <dt>Notes</dt>
    <dd>Another optional field. Put whatever text you want here that will make maintaining the value sets easier in the
      future.</dd>
  </dl>
</div>
