import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValueSetFile } from '../../model/value-set-file';

export interface ValidationErrorsData {
  file: ValueSetFile;
}

@Component({
  selector: 'app-validation-errors-dialog',
  templateUrl: './validation-errors-dialog.component.html',
  styleUrls: ['./validation-errors-dialog.component.css']
})
export class ValidationErrorsDialogComponent {

  @Input()
  public file: ValueSetFile;

  public canFix: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ValidationErrorsData) {
    this.canFix = true;
    for (const error of this.data.file.errors) {
      if (!error.isFixable) {
        this.canFix = false;
        break;
      }
    }
  }
}
