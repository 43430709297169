<mat-expansion-panel *ngFor="let vm of (includes$ | async)" [(expanded)]="vm.expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>{{vm.include.systemId}}</mat-panel-title>
    <mat-panel-description>{{vm.include.members.length}} members</mat-panel-description>
  </mat-expansion-panel-header>
  <ul *ngIf="!vm.showDomain">
    <li *ngFor="let member of vm.include.members" class="member">
      <button mat-icon-button color="warn" class="right" (click)="onRemoveClicked(member)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <code>{{member.code}}</code>
      {{member.display}}
    </li>
  </ul>
  <ng-container *ngIf="vm.showDomain">
    <mat-expansion-panel class="domain" expanded="true" *ngFor="let forDomain of vm.byDomain | keyvalue">
      <mat-expansion-panel-header>
        <mat-panel-title>{{forDomain.key}}</mat-panel-title>
        <mat-panel-description>{{forDomain.value.length}} members</mat-panel-description>
      </mat-expansion-panel-header>
      <ul>
        <li *ngFor="let member of forDomain.value" class="member">
          <button mat-icon-button color="warn" class="right" (click)="onRemoveClicked(member)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <code>{{member.code}}</code>
          {{member.display}}
        </li>
      </ul>
    </mat-expansion-panel>
  </ng-container>
</mat-expansion-panel>
<div *ngIf="(includes$ | async)?.length === 0" class="empty-membership">
  -- no members --
</div>
