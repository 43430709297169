<h4>Create New Value Set CSV</h4>
<form (ngSubmit)="onSubmit()" [formGroup]="newFileForm">
  <mat-form-field class="full-width" hintLabel="You probably want the default, CareEvolution">
    <mat-label>Folder</mat-label>
    <mat-select formControlName="folderName">
      <mat-option *ngFor="let folder of folders" [value]="folder.name">{{folder.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="folderName.invalid">{{getFolderErrorMessage()}}</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>File Name</mat-label>
    <input matInput type="text" formControlName="fileName" name="fileName" autocomplete="disabled" autofocus>
    <mat-error *ngIf="fileName.invalid">{{getFileErrorMessage()}}</mat-error>
  </mat-form-field>

  <button mat-raised-button type="submit" color="accent" [disabled]="newFileForm.invalid">Create</button>
</form>
