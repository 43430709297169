import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValueSet } from '../../model/value-set';
import { Coding } from '../../model/coding';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { ValueSetIncludeViewModel } from './value-set-include.view-model';
import { CodeSystem } from '../../model/code-system';

@Component({
  selector: 'app-value-set-membership',
  templateUrl: './value-set-membership.component.html',
  styleUrls: ['./value-set-membership.component.css']
})
export class ValueSetMembershipComponent implements OnInit {
  private destroy$ = new ReplaySubject<boolean>(1);

  @Input()
  public valueSet$: Observable<ValueSet>;

  @Input()
  public codeSystems$: Observable<CodeSystem[]>;

  @Output()
  public removeMembers = new EventEmitter<Coding[]>();

  private includeSubject = new ReplaySubject<ValueSetIncludeViewModel[]>(1);
  public includes$ = this.includeSubject.asObservable();

  constructor() { /* empty */ }

  ngOnInit(): void {
    combineLatest([this.valueSet$, this.codeSystems$]).pipe(
      takeUntil(this.destroy$),
      map(([vs, codeSystems]) => vs.includes.map(inc => new ValueSetIncludeViewModel(inc, codeSystems.find(cs => cs.id === inc.systemId))))
    ).subscribe(this.includeSubject);
  }

  onRemoveClicked(member: Coding) {
    this.removeMembers.emit([member]);
  }
}
