import { Component, Input, Output, OnDestroy, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CodeSystem } from '../../model/code-system';
import { ValueSet } from '../../model/value-set';
import { MatSelectChange } from '@angular/material/select';
import { RosettaService } from '../../rosetta.service';
import { ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Coding } from '../../model/coding';
import { WorkStateService } from '../../work-state.service';
import { CodingViewModel } from './coding.view-model';

@Component({
  selector: 'app-code-system-explorer',
  templateUrl: './code-system-explorer.component.html',
  styleUrls: ['./code-system-explorer.component.css']
})
export class CodeSystemExplorerComponent implements OnChanges, OnDestroy {
  private destroy$ = new ReplaySubject<boolean>(1);

  @Input()
  public codeSystems: CodeSystem[];

  @Input()
  public valueSet$: Observable<ValueSet>;

  @Output()
  public addMembers = new EventEmitter<Coding[]>();

  @Output()
  public removeMembers = new EventEmitter<Coding[]>();

  private search: BehaviorSubject<string>;
  public search$: Observable<string>;

  private codeSystemSubject = new ReplaySubject<CodeSystem>(1);
  public codeSystem$ = this.codeSystemSubject.asObservable();
  private initialCodeSystemSet = false;

  constructor(private rosetta: RosettaService, private workState: WorkStateService) {
    const initialSearch = this.workState.get('code-system-explorer', 'search');
    this.search = new BehaviorSubject<string>(initialSearch);
    this.search$ = this.search.asObservable().pipe(
      takeUntil(this.destroy$),
      debounceTime(150),
      distinctUntilChanged(),
    );
    this.search$.subscribe(search => {
      this.workState.set('code-system-explorer', 'search', search);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialCodeSystemSet && changes.codeSystems
      && changes.codeSystems.currentValue && changes.codeSystems.currentValue.length > 0) {
      this.initialCodeSystemSet = true;
      const initialiCodeSystemId = this.workState.get('code-system-explorer', 'systemId');
      if (initialiCodeSystemId) {
        const initialCodeSystem = this.codeSystems.find(cs => cs.id === initialiCodeSystemId);
        if (initialCodeSystem) {
          this.codeSystemSubject.next(initialCodeSystem);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onCodeSystemChanged(codeSystem: CodeSystem) {
    this.codeSystemSubject.next(codeSystem);
    this.workState.set('code-system-explorer', 'systemId', codeSystem.id);
  }

  searchChanged(newSearch: string) {
    this.search.next(newSearch);
  }

  onRemoveMembers(codingVMs: CodingViewModel[]) {
    const toRemove: Coding[] = [];

    codingVMs.forEach(vm => {
      if (vm.isMember) {
        toRemove.push(vm.coding);
        vm.isMember = false;
      }
    });

    this.removeMembers.emit(toRemove);
  }

  onAddMembers(codingVMs: CodingViewModel[]) {
    const toAdd: Coding[] = [];

    codingVMs.forEach(vm => {
      if (!vm.isMember) {
        toAdd.push(vm.coding);
        vm.isMember = true;
      }
    });

    this.addMembers.emit(toAdd);
  }
}
