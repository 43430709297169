import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValueSetFile, ValueSetFileOps } from '../../../model/value-set-file';
import { ValueSet } from '../../../model/value-set';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

function duplicateValueSetNameValidator(component: AddValueSetComponent): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const newName = control.value.toLowerCase();

    const existing = component.file.valueSets.find(valueSet => valueSet.name.toLowerCase() === newName);

    if (existing) {
      return { duplicateName: control.value };
    }

    return null;
  };
}

@Component({
  selector: 'app-add-value-set',
  templateUrl: './add-value-set.component.html',
  styleUrls: ['./add-value-set.component.css']
})
export class AddValueSetComponent implements OnInit {

  public addValueSetForm: FormGroup;
  public valueSetName: FormControl;
  public valueSetScope: FormControl;

  private scopes: string[];
  public scopes$: Observable<string[]>;

  @Input() file: ValueSetFile;
  @Output() valueSetAdded = new EventEmitter<ValueSet>();

  ngOnInit(): void {
    this.valueSetName = new FormControl('', [Validators.required, duplicateValueSetNameValidator(this)]);
    this.valueSetScope = new FormControl();

    this.addValueSetForm = new FormGroup({
      valueSetName: this.valueSetName,
      valueSetScope: this.valueSetScope
    });

    this.scopes = [...new Set(this.file.valueSets.map(vs => vs.scope))];
    this.scopes.sort();

    this.scopes$ = this.valueSetScope.valueChanges.pipe(
      startWith(''),
      map(value => this.applyScopeFilter(value))
    );
  }

  onSubmit(): void {
    const valueSet = ValueSetFileOps.getOrAdd(this.file, this.valueSetScope.value, this.valueSetName.value);
    this.valueSetName.setValue('');
    this.valueSetAdded.emit(valueSet);
  }

  getValueSetNameErrorMessage(): string {
    if (this.valueSetName.hasError('duplicateName')) {
      return 'A ValueSet with that name already exists';
    }

    return '';
  }

  private applyScopeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.scopes.filter(scope => scope.toLowerCase().indexOf(filterValue) >= 0);
  }
}
