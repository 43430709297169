import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RosettaService } from '../rosetta.service';
import { CsvFolder } from '../model/csv-folder';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValueSetFileService } from '../value-set-file.service';
import { ValueSetFile, ValueSetFileOps } from '../model/value-set-file';

function endsInCsvValidator(): ValidatorFn {
  const csvRegex = /\.csv$/i;
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && csvRegex.test(control.value)) {
      return null;
    }

    return { missingCsvFileExtension: control.value };
  };
}

function duplicateNameValidator(component: NewFileComponent): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (component.folders.length === 0) {
      return { noFolders: control.value };
    }

    const theFolder = component.folders.find(f => f.name === component.folderName.value);

    if (!theFolder) {
      return { nothingSelected: control.value };
    }

    const newName = control.value.toLowerCase();

    const existing = theFolder.files.find(csv => csv.toLowerCase() === newName);

    if (existing) {
      return { duplicateName: control.value };
    }

    return null;
  };
}

@Component({
  selector: 'app-new-file',
  templateUrl: './new-file.component.html',
  styleUrls: ['./new-file.component.css']
})
export class NewFileComponent implements OnInit {

  public folders: CsvFolder[] = [];

  public newFileForm: FormGroup;
  public folderName: FormControl;
  public fileName: FormControl;

  constructor(private router: Router, private rosetta: RosettaService, private valueSetFileService: ValueSetFileService) { }

  ngOnInit(): void {
    this.folderName = new FormControl('', [Validators.required]);
    this.fileName = new FormControl('', [Validators.required, endsInCsvValidator(), duplicateNameValidator(this)]);

    this.newFileForm = new FormGroup({
      folderName: this.folderName,
      fileName: this.fileName,
    });
    this.rosetta.getCsvFileList().subscribe(csvs => {
      this.folders.push(...csvs);
      this.newFileForm.setValue({ folderName: 'CareEvolution', fileName: 'myValueSets.csv' });
    });
  }

  onSubmit(): void {
    const pkg = new ValueSetFile();
    pkg.fileName = this.fileName.value;
    pkg.folderName = this.folderName.value;
    ValueSetFileOps.getOrAdd(pkg, 'myScope', 'myValueSet');
    this.valueSetFileService.setFile(pkg);
    this.router.navigate(['/app/edit']);
  }

  getFolderErrorMessage(): string {
    if (this.folderName.hasError('required')) {
      return 'Select a folder';
    }

    return '';
  }

  getFileErrorMessage(): string {
    if (this.fileName.hasError('required')) {
      return 'Enter a file name ending in .csv';
    }

    if (this.fileName.hasError('missingCsvFileExtension')) {
      return 'File name must end in ".csv"';
    }

    if (this.fileName.hasError('duplicateName')) {
      return 'File already exists in the folder';
    }

    return '';
  }
}
