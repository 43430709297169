import { Injectable } from '@angular/core';
import { parse, ParseConfig, unparse } from 'papaparse';
import { ValueSetFile, ValueSetFileOps } from './model/value-set-file';
import { Coding } from './model/coding';
import { CodeSystem } from './model/code-system';
import { IcdFormatterService } from './icd-formatter.service';
import { Observable, from } from 'rxjs';
import { flatMap, map, withLatestFrom } from 'rxjs/operators';
import { ValueSetOps } from './model/value-set';
import { LookupResult, RosettaService } from './rosetta.service';
import { ValidationError, ValidationErrorType } from './model/validation-error';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(
    private icdFormatter: IcdFormatterService,
    private rosetta: RosettaService
  ) {}

  upload(file: File): Observable<ValueSetFile> {
    return from(file.text()).pipe(
      map((rawCsv) => {
        const config: ParseConfig = {
          header: true,
          skipEmptyLines: true,
        };

        const result = parse(rawCsv, config);

        return result.data;
      }),
      flatMap((records) => {
        return this.buildValueSetFile(records, file.name);
      })
    );
  }

  download(valueSetFile: ValueSetFile, codeSystems: CodeSystem[]): string {
    const codeSystemsById = new Map<string, CodeSystem>(
      codeSystems.map((cs) => [cs.id, cs])
    );

    const records: CsvRecord[] = [];

    valueSetFile.valueSets.forEach((valueSet) => {
      valueSet.includes.forEach((include) => {
        const includeDomain = codeSystemsById.get(include.systemId).hasDomain;
        include.members.forEach((member) => {
          const record: CsvRecord = {
            ValueSet: valueSet.name,
            Scope: valueSet.scope || valueSetFile.defaultScope,
            System: include.systemId,
            Code: member.code,
            Name: member.display,
            Domain: includeDomain ? member.domain : '',
          };

          records.push(record);
        });
      });
    });

    return unparse(records);
  }

  public fixRecord(record: CsvRecord, defaultScope: string): CsvRecord {
    record.Code = record.Code.trim();
    record.System = record.System.trim();
    record.Scope = record.Scope?.trim() || defaultScope;

    if (record.Domain === 'ProcedureCode' || record.Domain === 'ServiceCode') {
      record.Domain = 'Procedure';
    }

    if (record.System === 'C4' || record.System === 'CPT') {
      record.System = 'HCPCS';
    }

    switch (record.System) {
      case 'C4':
      case 'CPT4':
        record.System = CodeSystem.Hcpcs;
        break;
      case 'ICD9':
        if (record.Domain === 'Procedure') {
          record.Code = this.icdFormatter.addDotForIcd9Procedure(record.Code);
          record.System = CodeSystem.Icd9CmProcedure;
        } else if (record.Domain === 'DiagnosisCode') {
          record.Code = this.icdFormatter.addDotForIcd9Diagnosis(record.Code);
          record.System = CodeSystem.Icd9CmDiagnosis;
        }
        break;
      case 'ICD10':
        if (record.Domain === 'Procedure') {
          record.System = CodeSystem.Icd10Pcs;
          record.Code = this.icdFormatter.padIcd10pcs(record.Code);
        } else if (record.Domain === 'DiagnosisCode') {
          record.Code = this.icdFormatter.addDotForIcd10Cm(record.Code);
          record.System = CodeSystem.Icd10Cm;
        }
        break;
      case 'ICD-10-PCS':
        record.Code = this.icdFormatter.padIcd10pcs(record.Code);
        break;
      case 'ICD-10-CM':
        record.Code = this.icdFormatter.addDotForIcd10Cm(record.Code);
        break;
      case 'UBFacilityType':
        if (
          (record.Code.length === 2 && record.Code[0] !== '0') ||
          record.Code.length === 1
        ) {
          record.Code = '0' + record.Code;
        }
        break;
      case 'UBREV':
      case 'UBTOB':
        if (record.Code.length === 3) {
          record.Code = '0' + record.Code;
        }
        break;
      case 'POS':
        if (record.Code.length === 1) {
          record.Code = '0' + record.Code;
        }
        break;
    }

    if (!record.Domain) {
      record.Domain = undefined;
    }

    return record;
  }

  public buildValueSetFile(
    rawRecords: CsvRecord[],
    fileName: string
  ): Observable<ValueSetFile> {
    const valueSetFile = new ValueSetFile();
    valueSetFile.fileName = fileName;

    if (fileName.endsWith('.csv')) {
      valueSetFile.defaultScope = fileName.substr(0, fileName.length - 4);
    } else {
      valueSetFile.defaultScope = fileName;
    }

    const fixedRecords = rawRecords.map((rr) =>
      this.fixRecord(rr, valueSetFile.defaultScope)
    );
    const records = this.dedupe(fixedRecords);

    const csvCodings = records.map(getCoding);

    return this.rosetta.lookupCodings(csvCodings).pipe(
      withLatestFrom(
        this.rosetta.codeSystems$,
        (lookupResults, codeSystems) => {
          this.populateValueSetFileFromLookupResults(
            valueSetFile,
            records,
            lookupResults,
            codeSystems
          );
          return valueSetFile;
        }
      ),
      withLatestFrom(this.rosetta.codeSystems$, (file, codeSystems) => {
        ValueSetFileOps.sort(file, codeSystems);
        return file;
      })
    );
  }

  public dedupe(records: CsvRecord[]): CsvRecord[] {
    const memberships = new Set<string>();

    const deduped: CsvRecord[] = [];

    function getKey(record: CsvRecord) {
      // using a unicode delimiter that will never, ever, ever show up in a value set file
      return `${record.Scope?.toLowerCase()}🌵${record.ValueSet.toLowerCase()}🌵${record.System.toLowerCase()}🌵${record.Code.toLowerCase()}`;
    }

    for (const record of records) {
      const key = getKey(record);
      if (!memberships.has(key)) {
        memberships.add(key);
        deduped.push(record);
      }
    }

    return deduped;
  }

  public populateValueSetFileFromLookupResults(
    valueSetFile: ValueSetFile,
    records: CsvRecord[],
    lookupResults: LookupResult[],
    codeSystems: CodeSystem[]
  ) {
    const codeSystemsById = new Map<string, CodeSystem>(
      codeSystems.map((cs) => [cs.id, cs])
    );
    const codingLookup = new Map<string, Map<string, LookupResult>>();
    for (const lookupResult of lookupResults) {
      if (!codeSystemsById.has(lookupResult.sourceCoding.systemId)) {
        continue;
      }

      let codeSystem = codingLookup.get(lookupResult.sourceCoding.systemId);
      if (!codeSystem) {
        codeSystem = new Map<string, LookupResult>();
        codingLookup.set(lookupResult.sourceCoding.systemId, codeSystem);
      }

      codeSystem.set(lookupResult.sourceCoding.code, lookupResult);
    }

    for (const record of records) {
      const codeSystem = codingLookup.get(record.System);

      if (!codeSystem) {
        valueSetFile.errors.push(
          new ValidationError(ValidationErrorType.InvalidCodeSystem, record)
        );
        continue;
      }

      const lookupResult = codeSystem.get(record.Code);

      if (!lookupResult || !lookupResult.found) {
        valueSetFile.errors.push(
          new ValidationError(ValidationErrorType.CodeNotFoundInSystem, record)
        );
        continue;
      }

      if (lookupResult.validatedCoding) {
        const valueSet = ValueSetFileOps.getOrAdd(
          valueSetFile,
          record.Scope,
          record.ValueSet
        );
        ValueSetOps.add(valueSet, lookupResult.validatedCoding);
      } else if (lookupResult.error) {
        valueSetFile.errors.push(
          new ValidationError(
            ValidationErrorType.Generic,
            record,
            lookupResult.error
          )
        );
      } else {
        valueSetFile.errors.push(
          new ValidationError(
            ValidationErrorType.Generic,
            record,
            'Unknown error'
          )
        );
      }
    }
  }
}

export interface CsvRecord {
  Scope?: string;
  ValueSet: string;
  System: string;
  Domain?: string;
  Code: string;
  Name: string;
}

export function getCoding(record: CsvRecord) {
  return new Coding(record.System, record.Code, record.Name, record.Domain);
}
