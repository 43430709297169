import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValueSetFile } from '../../model/value-set-file';
import { ValueSet } from '../../model/value-set';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

export interface ValueSetPropertiesDialogData {
  valueSet: ValueSet;
  file: ValueSetFile;
}

@Component({
  selector: 'app-value-set-properties-dialog',
  templateUrl: './value-set-properties-dialog.component.html',
  styleUrls: ['./value-set-properties-dialog.component.css']
})
export class ValueSetPropertiesDialogComponent implements OnInit {

  public existingName: string;

  public formGroup: FormGroup;
  public scopeFormControl: FormControl;
  public nameFormControl: FormControl;

  private scopes: string[];
  public scopes$: Observable<string[]>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ValueSetPropertiesDialogData,
    private dialogRef: MatDialogRef<ValueSetPropertiesDialogComponent>) { }

  ngOnInit(): void {
    this.existingName = this.data.valueSet.name;
    this.scopes = [...new Set(this.data.file.valueSets.map(vs => vs.scope))];
    this.scopes.sort();

    this.scopeFormControl = new FormControl(this.data.valueSet.scope);
    this.nameFormControl = new FormControl(this.data.valueSet.name, [Validators.required]);

    this.formGroup = new FormGroup({
      name: this.nameFormControl,
      scope: this.scopeFormControl,
    }, duplicateNameValidator(this.data.file, this.data.valueSet));

    this.scopes$ = this.scopeFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this.applyScopeFilter(value))
    );
  }

  getNameErrorMessage() {
    if (!this.formGroup.invalid) { return null; }

    if (this.nameFormControl.errors?.required) {
      return 'Name is required';
    } else if (this.formGroup.errors.duplicateName) {
      return this.formGroup.errors.duplicateName;
    } else {
      return null;
    }
  }

  submit() {
    this.dialogRef.close({
      scope: this.scopeFormControl.value,
      name: this.nameFormControl.value
    });
  }

  private applyScopeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.scopes.filter(scope => scope.toLowerCase().indexOf(filterValue) >= 0);
  }
}

export function duplicateNameValidator(file: ValueSetFile, valueSet: ValueSet): ValidatorFn {
  let implicitScope = file.fileName.toLowerCase();
  if (implicitScope.endsWith('.csv')) {
    implicitScope = implicitScope.substr(0, implicitScope.length - 4);
  }
  return (form: AbstractControl): { [key: string]: any } | null => {

    const scopeControl = form.get('scope');
    const nameControl = form.get('name');

    const checkName = nameControl.value.toLowerCase();
    const checkScope = scopeControl.value ? scopeControl.value.toLowerCase() : implicitScope;

    for (const vs of file.valueSets) {
      if (vs === valueSet) { continue; }

      if (vs.scope && vs.scope.toLowerCase() !== checkScope) { continue; }

      if (!vs.scope && implicitScope !== checkScope) { continue; }

      if (vs.name.toLowerCase() !== checkName) { continue; }

      return {
        duplicateName: `ValueSet ${vs.scope}.${vs.name} already exists`
      };
    }

    return null;
  };
}
