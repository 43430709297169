<h1 mat-dialog-title>Properties of {{existingName}}</h1>
<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Scope</mat-label>
      <input type="text" placeholder="Scope" matInput [formControl]="scopeFormControl" formControlName="scope"
        [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let scope of scopes$ | async" [value]="scope">
          {{scope}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <br>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput cdkFocusInitial formControlName="name" [formControl]="nameFormControl">
    </mat-form-field>
    <mat-error *ngIf="formGroup.invalid">{{getNameErrorMessage()}}</mat-error>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button mat-button [mat-dialog-close]="data" type="submit" [disabled]="formGroup.invalid">Ok</button>
  </div>
</form>
