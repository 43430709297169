export class CodeSystem {
  constructor(
    public id: string,
    public name: string,
    public url: string,
    public count: number,
    public sortAsString: boolean,
    public hasDomain: boolean) {
  }

  public static readonly Hcpcs = 'HCPCS';
  public static readonly Icd9CmDiagnosis = 'ICD-9-CM-Diagnosis';
  public static readonly Icd9CmProcedure = 'ICD-9-CM-Procedure';
  public static readonly Icd10Cm = 'ICD-10-CM';
  public static readonly Icd10Pcs = 'ICD-10-PCS';
}
