import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { saveAs } from 'file-saver';
import { takeUntil, map, take, delay } from 'rxjs/operators';
import { ValueSet, ValueSetOps } from '../model/value-set';
import { Coding } from '../model/coding';
import { ValueSetFile } from '../model/value-set-file';
import { CodeSystem } from '../model/code-system';
import { RosettaService } from '../rosetta.service';
import { ValueSetFileService } from '../value-set-file.service';
import { ResponsiveService } from '../responsive.service';
import { CsvService } from '../csv.service';
import { AfterSaveDialogComponent } from './after-save-dialog/after-save-dialog.component';
import { ValidationErrorsDialogComponent } from './validation-errors-dialog/validation-errors-dialog.component';
import { Router } from '@angular/router';
import { FixErrorsComponent } from '../fix-errors/fix-errors.component';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit, AfterViewInit, OnDestroy {
  public valueSet$ = new ReplaySubject<ValueSet>(1);
  public file: ValueSetFile;

  private codeSystems = new ReplaySubject<CodeSystem[]>(1);
  public codeSystems$: Observable<CodeSystem[]> = this.codeSystems.asObservable();

  public resizableHeight$ = new Subject<number>();

  private destroyed$ = new ReplaySubject(1);

  @ViewChild('editorcontainer') resizeElement: ElementRef;

  constructor(
    private rosetta: RosettaService,
    private valueSetFileService: ValueSetFileService,
    private responsive: ResponsiveService,
    private csvService: CsvService,
    public dialog: MatDialog,
    public router: Router) {
  }

  ngOnInit(): void {
    this.rosetta.codeSystems$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.codeSystems);

    this.valueSetFileService.file$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(file => {
        this.file = file;
        this.onValueSetSelected(file.valueSets[0]);
        if (this.file.errors.filter(e => !e.fixed).length > 0) {
          this.dialog.open(ValidationErrorsDialogComponent, {
            data: { file: this.file }
          }).afterClosed().subscribe(fixErrors => {
            if (fixErrors) {
              this.router.navigate(['/app/fix-errors']);
            } else {
              this.router.navigate(['/app/upload']);
            }
          });
        }
      });
  }

  ngAfterViewInit(): void {
    combineLatest([this.responsive.windowHeight$, this.valueSet$.pipe(delay(10))])
      .pipe(
        takeUntil(this.destroyed$),
        map(([height, vs]) => {
          return Math.max(0, height - this.resizeElement.nativeElement.offsetTop);
        }))
      .subscribe(this.resizableHeight$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  onValueSetSelected(valueSet: ValueSet) {
    this.valueSet$.next(valueSet);
  }

  onAddMembers(members: Coding[]) {
    combineLatest([this.valueSet$, this.codeSystems$]).pipe(
      take(1)
    ).subscribe(([valueSet, codeSystems]) => {
      members.forEach(member => ValueSetOps.add(valueSet, member));
      ValueSetOps.sort(valueSet, codeSystems);
      this.valueSet$.next(valueSet);
      this.valueSetFileService.saveFile(this.file);
    });
  }

  onRemoveMembers(members: Coding[]) {
    this.valueSet$.pipe(
      take(1),
    ).subscribe(valueSet => {
      members.forEach(member => ValueSetOps.remove(valueSet, member));
      this.valueSet$.next(valueSet);
      this.valueSetFileService.saveFile(this.file);
    });
  }

  onSave() {
    this.codeSystems$.pipe(
      take(1)
    ).subscribe(codeSystems => {
      const csvString = this.csvService.download(this.file, codeSystems);
      const csvBlob = new Blob([csvString], { type: 'text/csv' });
      saveAs(csvBlob, this.file.fileName);
      this.dialog.open(AfterSaveDialogComponent, {
        data: { file: this.file }
      });
    });
  }

  onValueSetUpdated() {
    this.valueSetFileService.saveFile(this.file);
  }
}
