import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export function loadConfig(http: HttpClient, configService: ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      http.get('config.json')
        .pipe(
          map((x: ConfigService) => {
            if (x.rosettaUrl.endsWith('/')) {
              x.rosettaUrl = x.rosettaUrl.substring(0, x.rosettaUrl.length - 1);
            }

            configService.rosettaUrl = x.rosettaUrl;
            resolve(true);
          }),
          catchError((x: { status: number }) => {
            if (x.status !== 404) {
              resolve(false);
            }
            configService.rosettaUrl = 'http://localhost:35135';
            resolve(true);
            return of({});
          })
        ).subscribe();
    });
  };
}
