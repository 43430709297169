import { Component, Input } from '@angular/core';
import { ValueSet } from '../../model/value-set';

@Component({
  selector: 'app-value-set-name-and-scope',
  templateUrl: './value-set-name-and-scope.component.html',
  styleUrls: ['./value-set-name-and-scope.component.css']
})
export class ValueSetNameAndScopeComponent {

  @Input()
  public valueSet: ValueSet;

  @Input()
  public scope: string;
  @Input()
  public name: string;

  get theScope() { return this.scope ?? this.valueSet?.scope; }
  get theName() { return this.name ?? this.valueSet?.name; }
}
