<table mat-table [dataSource]="dataSource" *ngIf="(valueSetName$ | async) as valueSetName">
  <ng-container matColumnDef="meta">
    <th mat-header-cell *matHeaderCellDef>
      <button mat-icon-button title="Add these codes to {{valueSetName}}" color="primary" (click)="addAllClicked()">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-icon-button title="Remove these codes from {{valueSetName}}" color="warn"
        (click)="removeAllClicked()">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element" class="meta">
      <button mat-icon-button *ngIf="element.isMember" title="Remove from {{valueSetName}}" color="warn"
        (click)="removeMemberClicked(element)">
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!element.isMember" title="Add to {{valueSetName}}" color="primary"
        (click)="addMemberClicked(element)">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let element"><code>{{element.code}}</code></td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display</th>
    <td mat-cell *matCellDef="let element">{{element.display}}</td>
  </ng-container>

  <ng-container matColumnDef="domain">
    <th mat-header-cell *matHeaderCellDef>Domain</th>
    <td mat-cell *matCellDef="let element">{{ element.domain}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="(columns$ | async)"></tr>
  <tr mat-row *matRowDef="let row; columns: (columns$ | async);" [ngClass]="{'member': row.isMember}"></tr>

</table>

<div *ngIf="(dataSource.searchCount$ | async) == 0" class="not-found">
  No codings found
</div>

<mat-paginator hidePageSize showFirstLastButtons [length]="dataSource.searchCount$ | async" [pageSize]="pageSize"
  [disabled]="dataSource.loading$ | async">
</mat-paginator>
