<div class="content">
  <div>Login by entering your Orchestrate API Key</div>

  <div><a href="https://rosetta.careevolution.com" target="_blank">Create an account</a></div>

  <div *ngIf="showBadApiKeyError" class="error">
    ⚠ There was a problem with your API key, please verify it and try again.
  </div>

  <div class="apikey">
      <mat-form-field appearance="outline">
          <mat-label>API Key</mat-label>
          <input matInput type="password" placeholder="API Key" [(ngModel)]="apiKey">
      </mat-form-field>
  </div>

  <div>
      <button mat-raised-button color="primary" (click)="login()" [disabled]="!apiKey">Login</button>
  </div>
</div>