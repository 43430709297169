import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IcdFormatterService {
  public addDotForIcd9Diagnosis(code: string): string {
    if (code[0] === 'E') {
      return this.addDotAtIndex(code, 4);
    } else {
      return this.addDotAtIndex(code, 3);
    }
  }

  public addDotForIcd9Procedure(code: string): string {
    return this.addDotAtIndex(code, 2);
  }

  public addDotForIcd10Cm(code: string): string {
    return this.addDotAtIndex(code, 3);
  }

  public padIcd10pcs(code: string): string {
    return code.padStart(7, '0');
  }

  private addDotAtIndex(code: string, index: number): string {
    if (code.length > index && code[index] !== '.') {
      return code.substr(0, index) + '.' + code.substr(index);
    } else {
      return code;
    }
  }
}
