<mat-grid-list cols="2" rowHeight="3em">
  <mat-grid-tile>
    <h4 *ngIf="file" class="left">{{file.folderName}}/{{file.fileName}}</h4>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="right">
      <button type="button" (click)="onSave()" title="Download CSV" mat-raised-button color="accent">Save</button>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<app-value-set-chooser [file]="file" (valueSetSelected)="onValueSetSelected($event)"
  (valueSetUpdated)="onValueSetUpdated()"></app-value-set-chooser>

<div #editorcontainer>
  <mat-grid-list cols="2" [rowHeight]="resizableHeight$ | async">
    <mat-grid-tile>
      <app-value-set-membership [valueSet$]="valueSet$" [codeSystems$]="codeSystems$"
        (removeMembers)="onRemoveMembers($event)">
      </app-value-set-membership>
    </mat-grid-tile>
    <mat-grid-tile>
      <app-code-system-explorer [valueSet$]="valueSet$" [codeSystems]="codeSystems$ | async"
        (addMembers)="onAddMembers($event)" (removeMembers)="onRemoveMembers($event)">
      </app-code-system-explorer>
    </mat-grid-tile>
  </mat-grid-list>
</div>
