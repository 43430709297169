import { ValueSetInclude } from '../../model/value-set-include';
import { Coding } from '../../model/coding';
import { CodeSystem } from '../../model/code-system';

export class ValueSetIncludeViewModel {
  public expanded = true;
  public readonly showDomain: boolean;
  public readonly byDomain: Map<string, Coding[]>;
  constructor(public include: ValueSetInclude, public codeSystem: CodeSystem) {
    this.showDomain = codeSystem.hasDomain;
    this.byDomain = new Map<string, Coding[]>();
    if (this.showDomain) {
      include.members.forEach(member => {
        let forDomain = this.byDomain.get(member.domain);
        if (!forDomain) {
          forDomain = [member];
          this.byDomain.set(member.domain, forDomain);
        } else {
          forDomain.push(member);
        }
      });
    }
  }
}
