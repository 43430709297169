<h3>Saved {{data.file.filename}}</h3>

<mat-dialog-content>
  Create a new issue in the Rosetta GitHub repository to get your new value sets hosted by Rosetta.
</mat-dialog-content>

<mat-dialog-actions>
  <a href="https://github.com/CareEvolution/Rosetta/issues/new" target="_blank" mat-button color="primary"
    mat-dialog-close cdkFocusInitial>
    Go to GitHub
  </a>
  <button type="button" mat-button mat-dialog-close>I'm not ready to publish these yet</button>
</mat-dialog-actions>
