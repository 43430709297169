import { Component, OnDestroy, OnInit } from '@angular/core';
import { CsvService } from '../csv.service';
import { ValueSetFileService } from '../value-set-file.service';
import { Router } from '@angular/router';
import { RosettaService } from '../rosetta.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit, OnDestroy {
  public progress: Observable<number>;
  private onDestroy = new ReplaySubject(1);

  constructor(
    private csv: CsvService,
    private rosetta: RosettaService,
    private valueSetFileService: ValueSetFileService,
    private router: Router) { /* empty */ }

  ngOnInit(): void {
    this.progress = this.rosetta.progress$.pipe(
      takeUntil(this.onDestroy)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next(true);
  }

  handleUpload(files: FileList) {
    if (files.length === 0) {
      return;
    }

    this.csv.upload(files[0]).subscribe(valueSetFile => {
      this.valueSetFileService.setFile(valueSetFile);
      this.router.navigate(['/app/edit']);
    });
  }
}
