import { Component, OnInit, OnDestroy } from '@angular/core';
import { RosettaService } from '../rosetta.service';
import { CsvFolder } from '../model/csv-folder';
import { ValueSetFileService } from '../value-set-file.service';
import { Router } from '@angular/router';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { ValueSetFile } from '../model/value-set-file';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  csvFolders: CsvFolder[];

  private destroy$ = new ReplaySubject<boolean>(1);
  public latestFile$ = new ReplaySubject<ValueSetFile>(1);
  public columns$ = new BehaviorSubject<number>(2);

  constructor(
    private rosetta: RosettaService,
    private valueSetFileService: ValueSetFileService,
    private router: Router) { }

  ngOnInit(): void {
    this.rosetta.getCsvFileList().subscribe(csvFolders => {
      this.csvFolders = csvFolders;
      this.csvFolders.sort((a, b) => a.name.localeCompare(b.name));
    });
    this.valueSetFileService.file$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.latestFile$);
    this.latestFile$.pipe(
      takeUntil(this.destroy$),
      map(pkg => pkg === null ? 2 : 3)
    ).subscribe(this.columns$);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onFileClick(folder: string, file: string) {
    this.rosetta.getValueSetFile(folder, file).subscribe(f => {
      this.valueSetFileService.setFile(f);
      this.router.navigate(['/app/edit']);
    });
  }
}
