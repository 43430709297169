import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { RosettaService } from './rosetta.service';
import { ResponsiveService } from './responsive.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'value-set-editor';

  constructor(
    private rosetta: RosettaService,
    private authService: AuthService,
    private responsive: ResponsiveService,
    private router: Router) {
  }

  get isAuthenticated(): boolean { return this.authService.isAuthenticated; }

  ngOnInit(): void {
    this.authService.$isAuthenticated.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.rosetta.getCodeSystems().toPromise();
      }
    });

    if (!this.authService.isAuthenticated) {
      this.router.navigate(['login']);
    }
  }

  ngAfterViewInit(): void {
    this.responsive.setWindowHeight(window.innerHeight);
  }

  onSignOutClicked(): void {
    this.authService.onSignOut();
    this.router.navigate(['login']);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.responsive.setWindowHeight(event.target.innerHeight);
  }
}
