import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public apiKey: string = null;
  public showBadApiKeyError = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) {
    this.apiKey = authService.apiKey;
  }

  ngOnInit(): void {
    const status = this.route.snapshot.queryParams.status;
    if (status === '401') {
      this.showBadApiKeyError = true;
    }
  }

  public login() {
    this.authService.onSignIn(this.apiKey);
    if (this.authService.isAuthenticated) {
      this.router.navigate(['app', 'home']);
    }
  }
}
