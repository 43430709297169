import { ValueSet, ValueSetOps } from './value-set';
import { RosettaFileBody } from '../file-repository/rosetta-file-body';
import { ValidationError } from './validation-error';
import { CodeSystem } from './code-system';

export class ValueSetFile implements RosettaFileBody {
  public fileName: string;
  public defaultScope: string;
  public folderName: string;
  public valueSets: ValueSet[] = [];
  public errors: ValidationError[] = [];
}

export class ValueSetFileOps {
  public static getOrAdd(file: ValueSetFile, scope: string, name: string): ValueSet {
    let valueSet = file.valueSets.find(vs => vs.name === name && vs.scope === scope);
    if (!valueSet) {
      valueSet = new ValueSet();
      valueSet.name = name;
      valueSet.scope = scope || file.defaultScope;
      file.valueSets.push(valueSet);
      ValueSetOps.valueSetsSortedByName(file);
    }

    return valueSet;
  }

  public static cloneValueSet(file: ValueSetFile, valueSet: ValueSet): ValueSet {
    const clone = ValueSetOps.clone(valueSet);
    file.valueSets.push(clone);
    ValueSetOps.valueSetsSortedByName(file);
    return clone;
  }

  public static deleteValueSet(file: ValueSetFile, valueSet: ValueSet): void {
    const index = file.valueSets.indexOf(valueSet);
    if (index >= 0) {
      file.valueSets.splice(index, 1);
    }
  }

  public static sort(file: ValueSetFile, codeSystems: CodeSystem[]): void {
    file.valueSets.sort((a, b): number => {
      const scopeDiff = a.scope.localeCompare(b.scope, 'en', { sensitivity: 'base' });

      if (scopeDiff === 0) {
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
      } else {
        return scopeDiff;
      }
    });

    for (const valueSet of file.valueSets) {
      ValueSetOps.sort(valueSet, codeSystems);
    }
  }
}
