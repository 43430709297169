import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

class AuthState {
    public apiKey: string;
}


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public $isAuthenticated: Observable<boolean>;
    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    private authState: AuthState;

    constructor() {
        const authStateJson = sessionStorage.getItem('authState');

        if (authStateJson) {
            this.authState = JSON.parse(authStateJson);
        } else {
            this.authState = new AuthState();
        }

        this.isAuthenticatedSubject = new BehaviorSubject<boolean>( !!this.authState.apiKey );
        this.$isAuthenticated = this.isAuthenticatedSubject.asObservable();
    }

    public get apiKey(): string {
        return this.authState.apiKey;
    }

    public get isAuthenticated(): boolean {
        return this.isAuthenticatedSubject.getValue();
    }

    public onSignIn(apiKey: string): void {
        this.authState.apiKey = apiKey;
        sessionStorage.setItem('authState', JSON.stringify(this.authState));
        this.isAuthenticatedSubject.next(true);
    }

    public onSignOut(): void {
        this.authState.apiKey = null;
        sessionStorage.removeItem('authState');
        this.isAuthenticatedSubject.next(false);
    }
}
