import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EditComponent } from './edit/edit.component';
import { UploadComponent } from './upload/upload.component';
import { NewFileComponent } from './new-file/new-file.component';
import { FixErrorsComponent } from './fix-errors/fix-errors.component';
import { LoginComponent } from './login/login.component';
import { AuthorizedGuard } from './guards/authorized.guard';
import { NotAuthorizedGuard } from './guards/not-authorized.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [NotAuthorizedGuard] },
  {
    path: 'app', canActivate: [AuthorizedGuard], children: [
      { path: 'home', component: HomeComponent },
      { path: 'edit', component: EditComponent },
      { path: 'upload', component: UploadComponent },
      { path: 'new-file', component: NewFileComponent },
      { path: 'fix-errors', component: FixErrorsComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
