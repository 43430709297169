import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { RosettaService } from '../../rosetta.service';
import { finalize } from 'rxjs/operators';
import { CodingViewModel } from './coding.view-model';
import { ValueSet, ValueSetOps } from '../../model/value-set';

export class CodeSystemDataSource implements DataSource<CodingViewModel>
{
  private codingsSubject = new BehaviorSubject<CodingViewModel[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private searchCountSubject = new ReplaySubject<number>(1);

  public codings$ = this.codingsSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();
  public searchCount$ = this.searchCountSubject.asObservable();

  constructor(private rosetta: RosettaService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<CodingViewModel[]> {
    return this.codingsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.codingsSubject.complete();
    this.loadingSubject.complete();
  }

  load(valueSet: ValueSet, systemId: string, pageIndex: number, pageSize: number, search: string) {
    this.loadingSubject.next(true);
    this.rosetta.searchForCodes(systemId, search, pageIndex + 1, pageSize).pipe(
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(page => {
      this.codingsSubject.next(page.codings.map(c => {
        const vm = new CodingViewModel(c);
        vm.isMember = ValueSetOps.checkIsMember(valueSet, c);
        return vm;
      }));
      this.searchCountSubject.next(page.searchTotal);
    });
  }
}
