import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValueSet } from '../../model/value-set';
import { ValueSetFile, ValueSetFileOps } from '../../model/value-set-file';
import { ValueSetOps } from '../../model/value-set';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ValueSetPropertiesDialogComponent, ValueSetPropertiesDialogData } from '../value-set-properties-dialog/value-set-properties-dialog.component';

@Component({
  selector: 'app-value-set-chooser',
  templateUrl: './value-set-chooser.component.html',
  styleUrls: ['./value-set-chooser.component.css']
})
export class ValueSetChooserComponent {
  showAddValueSet = false;
  valueSet: ValueSet;

  @Input()
  public file: ValueSetFile;

  @Output()
  public valueSetSelected = new EventEmitter<ValueSet>();
  @Output()
  public valueSetUpdated = new EventEmitter<ValueSet>();

  valueSetOps = ValueSetOps;

  constructor(public dialog: MatDialog) { /* empty */ }

  clearValueSet(): void {
    this.valueSet = null;
  }

  deleteValueSet() {
    const content = this.valueSet.scope ?
      `Delete value set ${this.valueSet.scope}.${this.valueSet.name}?` :
      `Delete value set ${this.valueSet.name}?`;

    const dialogReference = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete',
        content,
        yesButtonText: 'Delete',
        noButtonText: 'Cancel'
      }
    });

    dialogReference.afterClosed().subscribe((result: any) => {
      if (result) {
        ValueSetFileOps.deleteValueSet(this.file, this.valueSet);
        this.clearValueSet();
        this.valueSetUpdated.emit(this.valueSet);
      }
    });
  }

  editValueSet() {
    const dialogReference = this.dialog.open(ValueSetPropertiesDialogComponent, {
      data: {
        valueSet: this.valueSet,
        file: this.file
      },
      width: '400px'
    });
    dialogReference.afterClosed().subscribe((result: any) => {
      if (result) {
        this.valueSet.name = result.name;
        this.valueSet.scope = result.scope;
        ValueSetOps.valueSetsSortedByName(this.file);
        this.valueSetUpdated.emit(this.valueSet);
      }
    });
  }

  cloneValueSet() {
    const clone = ValueSetFileOps.cloneValueSet(this.file, this.valueSet);
    this.onValueSetAdded(clone);
  }

  changeValueSet(valueSet: ValueSet): void {
    this.valueSet = valueSet;
    this.valueSetSelected.emit(valueSet);
  }

  toggleShowAddValueSet(showAddValueSet: boolean): void {
    this.showAddValueSet = showAddValueSet;
  }

  onValueSetAdded(valueSet: ValueSet) {
    this.showAddValueSet = false;
    this.changeValueSet(valueSet);
    this.valueSetUpdated.emit(valueSet);
  }

  onValueSetSelected(valueSet: ValueSet) {
    this.changeValueSet(valueSet);
  }
}
