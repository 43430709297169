import { Coding } from '../../model/coding';

export class CodingViewModel {
  public readonly coding: Coding;
  public readonly code: string;
  public readonly display: string;
  public readonly domain: string;
  public isMember: boolean;

  constructor(coding: Coding) {
    this.coding = coding;
    this.code = coding.code;
    this.display = coding.display;
    this.domain = coding.domain;
  }
}
