import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { RosettaService } from '../../../rosetta.service';
import { CodeSystemDataSource } from '../code-system.datasource';
import { MatPaginator } from '@angular/material/paginator';
import { CodeSystem } from '../../../model/code-system';
import { ValueSet } from '../../../model/value-set';
import { ReplaySubject, Observable, combineLatest } from 'rxjs';
import { takeUntil, withLatestFrom, take, map } from 'rxjs/operators';
import { CodingViewModel } from '../coding.view-model';

const COLUMNS_WITH_DOMAIN = ['meta', 'code', 'display', 'domain'];
const COLUMNS_WITHOUT_DOMAIN = ['meta', 'code', 'display'];

@Component({
  selector: 'app-basic-coding-table',
  templateUrl: './basic-coding-table.component.html',
  styleUrls: ['./basic-coding-table.component.css']
})
export class BasicCodingTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new ReplaySubject<boolean>(1);

  public dataSource: CodeSystemDataSource;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() search$: Observable<string>;
  @Input() codeSystem$: Observable<CodeSystem>;
  @Input() valueSet$: Observable<ValueSet>;

  @Output() public addMembers = new EventEmitter<CodingViewModel[]>();
  @Output() public removeMembers = new EventEmitter<CodingViewModel[]>();

  public columns$ = new ReplaySubject<string[]>(1);
  public valueSetName$ = new ReplaySubject<string>(1);

  public pageSize = 10;

  constructor(private rosetta: RosettaService) {
    this.dataSource = new CodeSystemDataSource(rosetta);
  }

  ngOnInit(): void {
    // if the user changes the search or the code system, reset the paging and load the table
    combineLatest([this.search$, this.codeSystem$]).pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.valueSet$)
    )
      .subscribe(([[search, codeSystem], valueSet]) => {
        if (codeSystem.hasDomain) {
          this.columns$.next(COLUMNS_WITH_DOMAIN);
        } else {
          this.columns$.next(COLUMNS_WITHOUT_DOMAIN);
        }

        this.paginator.pageIndex = 0;
        this.dataSource.load(valueSet, codeSystem.id, 0, this.pageSize, search);
      });

    // if the user changes the value set, redo the last search to regenerate the coding VMs, which indicate
    // membership in the current value set
    combineLatest([this.valueSet$, this.search$, this.codeSystem$]).pipe(
      takeUntil(this.destroy$)
    )
      .subscribe(([valueSet, search, codeSystem]) => {
        this.dataSource.load(valueSet, codeSystem.id, this.paginator.pageIndex, this.pageSize, search);
        this.valueSetName$.next(valueSet.name);
      });
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.search$, this.codeSystem$, this.valueSet$)
    ).subscribe(([page, search, codeSystem, valueSet]) => {
      this.dataSource.load(valueSet, codeSystem.id, page.pageIndex, page.pageSize, search);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  removeMemberClicked(codingVM: CodingViewModel) {
    this.removeMembers.emit([codingVM]);
  }

  addMemberClicked(codingVM: CodingViewModel) {
    this.addMembers.emit([codingVM]);
  }

  removeAllClicked() {
    this.dataSource.codings$.pipe(
      take(1),
    ).subscribe(codingVMs => {
      this.removeMembers.emit(codingVMs);
    });
  }

  addAllClicked() {
    this.dataSource.codings$.pipe(
      take(1),
    ).subscribe(codingVMs => {
      this.addMembers.emit(codingVMs);
    });
  }
}
