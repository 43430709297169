<div *ngIf="valueSet != null" class="current-value-set">
  Current ValueSet: <app-value-set-name-and-scope [valueSet]="valueSet"></app-value-set-name-and-scope>
  <button mat-raised-button (click)="clearValueSet()" class="changeValueSet">
    <mat-icon>menu_open</mat-icon>
    Change ValueSet
  </button>

  <button mat-raised-button (click)="deleteValueSet()" class="delete-value-set" title="Delete {{valueSet.name}}">
    <mat-icon>delete</mat-icon>
    Delete
  </button>

  <button mat-raised-button (click)="cloneValueSet()" class="clone-value-set" title="Clone {{valueSet.name}}">
    <mat-icon>content_copy</mat-icon>
    Clone
  </button>

  <button mat-raised-button (click)="editValueSet()" class="edit-value-set" title="Edit {{valueSet.name}}">
    <mat-icon>edit</mat-icon>
    Edit
  </button>
</div>

<div *ngIf=" valueSet==null && file !=null">
  <button mat-raised-button color="accent" (click)="toggleShowAddValueSet(true)" *ngIf="! showAddValueSet">
    <mat-icon>add</mat-icon>
    Add new ValueSet
  </button>
  <app-add-value-set *ngIf="showAddValueSet" (valueSetAdded)="onValueSetAdded($event)" [file]="file">
  </app-add-value-set>

  <h5>Select a ValueSet:</h5>

  <mat-nav-list>
    <mat-list-item *ngFor="let valueSet of file.valueSets" (click)="onValueSetSelected(valueSet)">
      <mat-icon>list</mat-icon>
      <app-value-set-name-and-scope [valueSet]="valueSet"></app-value-set-name-and-scope>
    </mat-list-item>
  </mat-nav-list>
</div>
