import { Injectable } from '@angular/core';
import { ValueSetFile } from './model/value-set-file';
import { FileRepositoryService } from './file-repository/file-repository.service';
import { ReplaySubject } from 'rxjs';
import { RosettaFileEntry } from './file-repository/rosetta-file-entry';
import { ValueSetOps } from './model/value-set';
import { RosettaService } from './rosetta.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ValueSetFileService {

  private entry: RosettaFileEntry = null;
  private readonly file = new ReplaySubject<ValueSetFile>(1);
  public readonly file$ = this.file.asObservable();

  constructor(private fileRepository: FileRepositoryService, private rosettaService: RosettaService) {
  }

  init() {
    const lastEntry = this.fileRepository.getLatestEntry();
    if (lastEntry) {
      const body = this.fileRepository.retrieveBody(lastEntry.key) as ValueSetFile;

      this.rosettaService.codeSystems$.pipe(
        take(1),
      ).subscribe(codeSystems => {
        body.valueSets.forEach(valueSet => ValueSetOps.sort(valueSet, codeSystems));
        ValueSetOps.valueSetsSortedByName(body);
        this.entry = lastEntry;
        this.file.next(body);
      });
    }
  }

  setFile(file: ValueSetFile) {
    this.file.next(file);

    if (!this.entry) {
      this.entry = this.fileRepository.create();
    }
    this.entry.folder = file.folderName;
    this.entry.name = file.fileName;

    this.fileRepository.saveEntry(this.entry, file);
  }

  saveFile(file: ValueSetFile) {
    this.fileRepository.saveEntry(this.entry, file);
  }
}
