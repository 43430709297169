import { Coding } from './coding';
import { ValueSetInclude } from './value-set-include';
import { CodeSystem } from './code-system';
import { ValueSetFile } from './value-set-file';

export class ValueSet {
  name: string;
  scope: string;
  includes: ValueSetInclude[] = [];
}

export class ValueSetOps {
  public static add(valueSet: ValueSet, coding: Coding) {
    let include = valueSet.includes.find(inc => inc.systemId === coding.systemId);
    if (!include) {
      include = new ValueSetInclude();
      include.systemId = coding.systemId;
      valueSet.includes.push(include);
    }
    include.members.push(coding);
  }

  public static remove(valueSet: ValueSet, coding: Coding) {
    const include = valueSet.includes.find(inc => inc.systemId === coding.systemId);
    if (!include) {
      return;
    }

    const memberIdx = include.members.findIndex(c => c.code === coding.code && c.domain === coding.domain);

    if (memberIdx >= 0) {
      include.members.splice(memberIdx, 1);
    }
  }

  public static checkIsMember(valueSet: ValueSet, coding: Coding): boolean {
    const include = valueSet.includes.find(inc => inc.systemId === coding.systemId);
    if (!include) {
      return false;
    }

    const memberIdx = include.members.findIndex(c => c.code === coding.code && c.domain === coding.domain);

    return memberIdx >= 0;
  }

  public static compareNameAndScope(vs1: ValueSet, vs2: ValueSet): number {
    const scope1 = vs1?.scope ?? '';
    const scope2 = vs2?.scope ?? '';

    const scopeCompare = scope1.localeCompare(scope2, 'en', { sensitivity: 'base' });

    if (scopeCompare !== 0) {
      return scopeCompare;
    }

    const name1 = (vs1 != null ? vs1.name : '');
    const name2 = (vs2 != null ? vs2.name : '');
    return name1.localeCompare(name2, 'en', { sensitivity: 'base' });
  }

  public static valueSetsSortedByName(file: ValueSetFile): ValueSet[] {
    if (file == null) {
      return [];
    }

    return file.valueSets.sort(this.compareNameAndScope);
  }

  public static sort(valueSet: ValueSet, codeSystems: CodeSystem[]): void {
    valueSet.includes = sortAsString(valueSet.includes, inc => inc.systemId);

    valueSet.includes.forEach(inc => {
      const codeSystem = codeSystems.find(cs => cs.id === inc.systemId);

      if (!codeSystem) {
        inc.members = sortAsString(inc.members, mem => mem.code);
      } else if (codeSystem.sortAsString) {
        if (codeSystem.hasDomain) {
          inc.members = inc.members.sort((a, b) => {
            const domainCompare = compareAsStrings(a, b, mem => mem.domain);

            if (domainCompare !== 0) {
              return domainCompare;
            }

            return compareAsStrings(a, b, mem => mem.code);
          });
        } else {
          inc.members = sortAsString(inc.members, mem => mem.code);
        }
      } else {
        inc.members = sortAsNumbers(inc.members, mem => mem.code);
      }
    });
  }

  public static clone(original: ValueSet): ValueSet {
    const clone = new ValueSet();
    clone.name = original.name + ' (copy)';
    clone.scope = original.scope;
    clone.includes = original.includes.map(ValueSetOps.cloneInclude);

    return clone;
  }

  public static cloneInclude(original: ValueSetInclude): ValueSetInclude {
    const clone = new ValueSetInclude();
    clone.systemId = original.systemId;
    clone.members = original.members.map(ValueSetOps.cloneMember);

    return clone;
  }

  public static cloneMember(original: Coding): Coding {
    return new Coding(original.systemId, original.code, original.display, original.domain);
  }
}

function compareAsNumbers<T>(a: T, b: T, selector: (item: T) => string) {
  return parseInt(selector(a), 10) - parseInt(selector(b), 10);
}

function sortAsNumbers<T>(array: T[], selector: (item: T) => string): T[] {
  return array.sort((a, b) => compareAsNumbers(a, b, selector));
}

function compareAsStrings<T>(a: T, b: T, selector: (item: T) => string) {
  return selector(a).localeCompare(selector(b), 'en', { sensitivity: 'base' });
}

function sortAsString<T>(array: T[], selector: (item: T) => string): T[] {
  return array.sort((a, b) => compareAsStrings(a, b, selector));
}
