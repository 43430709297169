import { Coding } from '../model/coding';

export class PossibleFixViewModel {
  public readonly coding: Coding;
  public readonly code: string;
  public readonly display: DisplayPhraseViewModel[];
  public readonly domain: string;

  constructor(coding: Coding, sourceWords: Set<string>) {
    this.coding = coding;
    this.code = coding.code;
    // this split is different than the one to build sourceWords since we want to preserve punctuation and symbols for the display
    this.display = [];

    const currentPhrase = [];
    let currentPhraseInSource = true;

    for (const word of coding.display.split(/(\s|\b)/).map(w => w.trim()).filter(w => w !== '')) {
      const thisWordInSource = sourceWords.has(word.toLowerCase());

      if (currentPhrase.length === 0) {
        currentPhrase.push(word);
        currentPhraseInSource = thisWordInSource;
      }
      else {
        if (currentPhraseInSource === thisWordInSource) {
          currentPhrase.push(word);
        } else {
          this.display.push(new DisplayPhraseViewModel(currentPhrase, currentPhraseInSource));
          currentPhrase.splice(0, currentPhrase.length, [word]);
          currentPhraseInSource = thisWordInSource;
        }
      }
    }

    if (currentPhrase.length) {
      this.display.push(new DisplayPhraseViewModel(currentPhrase, currentPhraseInSource));
    }

    this.domain = coding.domain;
  }
}

export class DisplayPhraseViewModel {
  public readonly phrase: string;
  constructor(words: string[], public readonly inSource: boolean) {
    this.phrase = words.join(' ');
  }
}
