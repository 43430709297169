<form (ngSubmit)="onSubmit()" [formGroup]="addValueSetForm">
  <mat-form-field class="full-width">
    <mat-label>Scope</mat-label>
    <input matInput type="text" placeholder="Scope" [formControl]="valueSetScope" formControlName="valueSetScope"
      [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let scope of scopes$ | async" [value]="scope">
        {{scope}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Name</mat-label>
    <input matInput type="text" formControlName="valueSetName" name="valueSetName" autocomplete="disabled" autofocus>
    <mat-error *ngIf="valueSetName.invalid">{{getValueSetNameErrorMessage()}}</mat-error>
  </mat-form-field>

  <button mat-raised-button type="submit" color="accent" [disabled]="addValueSetForm.invalid">Add</button>

  <span>{{file.fileName}}</span>
</form>
