<mat-form-field>
  <mat-label>Code System</mat-label>
  <mat-select [(ngModel)]="codeSystem" (ngModelChange)="codeSystemChange.emit(codeSystem)">
    <mat-option>
      <ngx-mat-select-search [formControl]="codeSystemFilterCtrl" placeholderLabel="Code System"
        noEntriesFoundLabel="None Found">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="cs" *ngFor="let cs of (filteredCodeSystems$ | async)">{{cs.id}}</mat-option>
  </mat-select>
</mat-form-field>
