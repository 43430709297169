import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CodeSystem } from '../model/code-system';

@Component({
  selector: 'app-code-system-selector',
  templateUrl: './code-system-selector.component.html',
  styleUrls: ['./code-system-selector.component.css']
})
export class CodeSystemSelectorComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public codeSystems: CodeSystem[];
  @Input()
  public codeSystem: CodeSystem;
  @Output()
  public codeSystemChange = new EventEmitter<CodeSystem>();

  public filteredCodeSystems$ = new ReplaySubject<CodeSystem[]>(1);

  public codeSystemFilterCtrl: FormControl = new FormControl();

  private destroy$ = new ReplaySubject<boolean>(1);

  ngOnInit(): void {
    this.codeSystemFilterCtrl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map((value: string) => {
          if (!value || value.length === 0) {
            return this.codeSystems;
          }

          const searchValue = value.toLowerCase();
          return this.codeSystems.filter(cs => cs.name.toLowerCase().indexOf(searchValue) >= 0 ||
            cs.id.toLowerCase().indexOf(searchValue) >= 0);
        }))
      .subscribe(this.filteredCodeSystems$);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.codeSystems && changes.codeSystems.currentValue && changes.codeSystems.currentValue.length > 0) {
      this.filteredCodeSystems$.next(changes.codeSystems.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
