import { CsvRecord } from '../csv.service';

export enum ValidationErrorType {
  Generic,
  InvalidCodeSystem,
  CodeNotFoundInSystem,
}

export class ValidationError {
  public fixed = false;

  public constructor(public errorType: ValidationErrorType, public record: CsvRecord, public genericMessage: string = null) { }

  public get message(): string {
    switch (this.errorType) {
      case ValidationErrorType.CodeNotFoundInSystem:
        return `Could not find code ${this.record.Code} in system ${this.record.System}`;
      case ValidationErrorType.InvalidCodeSystem:
        return `Invalid system ${this.record.System}`;
      default:
        return this.genericMessage;
    }
  }

  public get isFixable(): boolean {
    return this.errorType === ValidationErrorType.InvalidCodeSystem || this.errorType === ValidationErrorType.CodeNotFoundInSystem;
  }
}
