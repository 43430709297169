import { Injectable } from '@angular/core';
import { interval, BehaviorSubject } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  private windowHeight = new BehaviorSubject<number>(0);
  public windowHeight$ = this.windowHeight.pipe(debounce(() => interval(100)));

  setWindowHeight(height: number) {
    this.windowHeight.next(height);
  }
}
