<app-code-system-selector [codeSystems]="codeSystems" [codeSystem]="codeSystem$ | async"
  (codeSystemChange)="onCodeSystemChanged($event)"></app-code-system-selector>

<mat-form-field>
  <input matInput placeholder="Search" #input [ngModel]="search$ | async" (ngModelChange)="searchChanged(input.value)">
</mat-form-field>

<!-- eventually we'll want cool code system explorers that exploit individual code systems' metadata (e.g. a different component for RxNorm, LOINC, etc.) -->
<app-basic-coding-table [search$]="search$" [codeSystem$]="codeSystem$" [valueSet$]="valueSet$"
  (addMembers)="onAddMembers($event)" (removeMembers)="onRemoveMembers($event)"></app-basic-coding-table>
