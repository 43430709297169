import { ThrowStmt } from '@angular/compiler';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, first, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CodeSystem } from '../model/code-system';
import { Coding } from '../model/coding';
import { ValidationError } from '../model/validation-error';
import { ValueSetOps } from '../model/value-set';
import { ValueSetFile, ValueSetFileOps } from '../model/value-set-file';
import { RosettaService } from '../rosetta.service';
import { ValueSetFileService } from '../value-set-file.service';
import { FixErrorsDataSource } from './fix-errors.datasource';
import { PossibleFixViewModel } from './possible-fix.view-model';

export interface FixErrorsData {
  file: ValueSetFile;
}

// because Excel sometimes thinks LOINC codes are dates
const monthMap = new Map<string, string>([
  ['Jan', '1'],
  ['Feb', '2'],
  ['Mar', '3'],
  ['Apr', '4'],
  ['May', '5'],
  ['Jun', '6'],
  ['Jul', '7'],
  ['Aug', '8'],
  ['Sep', '9'],
  ['Oct', '10'],
  ['Nov', '11'],
  ['Dev', '12'],
]);

@Component({
  selector: 'app-fix-errors',
  templateUrl: './fix-errors.component.html',
  styleUrls: ['./fix-errors.component.css'],
  providers: [FixErrorsDataSource]
})
export class FixErrorsComponent implements OnInit, AfterViewInit, OnDestroy {

  public fileName: string;
  public numberOfErrors: number;
  public progress: number;

  private errorsByCoding = new Map<string, ValidationError[]>();
  public codingKeys: string[] = [];

  public pageSize = 25;
  public errorIndex = 0;
  public errorBatch: ValidationError[];

  private candidatesSubject = new Subject<Coding[]>();
  public candidates$ = this.candidatesSubject.asObservable();

  private codeSystems = new ReplaySubject<CodeSystem[]>(1);
  public codeSystems$: Observable<CodeSystem[]> = this.codeSystems.asObservable();

  private codeSystemSubject = new ReplaySubject<CodeSystem>();
  public codeSystem$ = this.codeSystemSubject.asObservable();

  public searchCode: string;
  public searchDisplay: string;

  private searchCodeSubject = new ReplaySubject<string>(1);
  private searchDisplaySubject = new ReplaySubject<string>(1);
  private pageSubject = new ReplaySubject<number>(1);

  private file: ValueSetFile;

  private destroy$ = new ReplaySubject<boolean>(1);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  static getConceptSearch(code: string): string {
    const excelScientificNotationMatch = code.match(/^(\d+)\.(\d+)[Ee][+-]\d+$/);

    if (excelScientificNotationMatch) {
      return excelScientificNotationMatch[1] + excelScientificNotationMatch[2];
    }

    const excelDateMatch = code.match(/^(\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)$/);

    if (excelDateMatch) {
      return `${monthMap.get(excelDateMatch[2])}-${excelDateMatch[1]}`;
    }

    if (code.match(/^[0-9.]+$/)) {
      return '0' + code;
    }
    else {
      return code;
    }
  }

  constructor(
    public dataSource: FixErrorsDataSource,
    private rosetta: RosettaService,
    private valueSetFileSerivce: ValueSetFileService,
    private router: Router) { }

  ngOnInit() {
    this.rosetta.codeSystems$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.codeSystems);

    this.valueSetFileSerivce.file$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.codeSystems$)
    ).subscribe(([file, codeSystems]) => {
      this.file = file;
      if (file.folderName) {
        this.fileName = file.folderName + '/' + file.fileName;
      } else {
        this.fileName = file.fileName;
      }

      for (const error of file.errors) {
        const key = this.getCodingKey(error);
        if (!this.errorsByCoding.has(key)) {
          this.errorsByCoding.set(key, [error]);
          this.codingKeys.push(key);
        } else {
          this.errorsByCoding.get(key).push(error);
        }
      }

      this.errorBatch = this.errorsByCoding.get(this.codingKeys[this.errorIndex]);

      this.numberOfErrors = this.codingKeys.length;
      this.progress = 0 * 100 / this.numberOfErrors;
      this.searchCode = FixErrorsComponent.getConceptSearch(this.errorBatch[0].record.Code);
      this.searchCodeSubject.next(this.searchCode);
      this.codeSystemSubject.next(codeSystems.find(cs => cs.id === this.errorBatch[0].record.System));

      this.searchDisplay = null;
      this.searchDisplaySubject.next(null);

      this.paginator.page.subscribe((page: any) => {
        this.pageSubject.next(page.pageIndex);
      });

      this.getFirstPage();
    });
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.pageSubject,
      this.searchCodeSubject,
      this.searchDisplaySubject,
      this.codeSystemSubject,
    ]).pipe(
      takeUntil(this.destroy$),
      debounceTime(150),
    ).subscribe(([pageIndex, searchCode, searchDisplay, codeSystem]) => {
      if (!codeSystem) {
        return;
      }
      const firstRecord = this.errorBatch[0].record;
      this.dataSource.load(codeSystem.id, pageIndex, this.pageSize, searchCode, searchDisplay, firstRecord.Name);
    });
    this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  getFirstPage() {
    this.pageSubject.next(0);
  }

  removeMembers() {
    for (const error of this.errorBatch) {
      error.fixed = true;
    }

    this.moveToNextError();
  }

  acceptFix(candidate: PossibleFixViewModel) {
    for (const error of this.errorBatch) {
      error.fixed = true;

      const valueSet = ValueSetFileOps.getOrAdd(this.file, error.record.Scope, error.record.ValueSet);
      ValueSetOps.add(valueSet, candidate.coding);
    }

    this.moveToNextError();
  }

  moveToNextError() {
    this.errorIndex++;
    this.progress = this.errorIndex * 100 / this.numberOfErrors;
    if (this.errorIndex >= this.codingKeys.length) {
      this.router.navigate(['/app/edit']);
    } else {
      this.initializeErrorBatch();
    }
  }

  private initializeErrorBatch() {
    this.codeSystems$.pipe(
      take(1)
    ).subscribe(codeSystems => {
      this.errorBatch = this.errorsByCoding.get(this.codingKeys[this.errorIndex]);
      const firstRecord = this.errorBatch[0].record;

      this.searchCode = FixErrorsComponent.getConceptSearch(firstRecord.Code);
      this.searchCodeSubject.next(this.searchCode);
      this.searchDisplay = null;
      this.searchDisplaySubject.next(this.searchDisplay);

      const codeSystem = codeSystems.find(cs => cs.id === firstRecord.System);

      this.codeSystemSubject.next(codeSystem);
    });
  }

  searchCodeChanged(search: string) {
    this.searchCodeSubject.next(search);
  }

  searchDisplayChanged(search: string) {
    this.searchDisplaySubject.next(search);
  }

  onCodeSystemChange(codeSystem: CodeSystem) {
    this.codeSystemSubject.next(codeSystem);
  }

  private getCodingKey(error: ValidationError): string {
    // using a unicode delimiter that will never, ever, ever show up in a value set file
    return `${error.record.System.toLowerCase()}🌵${error.record.Code.toLowerCase()}`;
  }
}
