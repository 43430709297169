<h3>Get Started!</h3>

<mat-grid-list [cols]="columns$ | async" rowHeight="100px">
  <mat-grid-tile *ngIf="(latestFile$ | async) as latestFile">
    <button mat-raised-button color="accent" [routerLink]="['/app/edit']">
      <mat-icon>text_snippet</mat-icon>
      Resume work on {{latestFile.fileName}}
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <a mat-raised-button color="accent" [routerLink]="['/app/upload']">
      <mat-icon>cloud_upload</mat-icon>
      Upload a CSV
    </a>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-raised-button color="accent" [routerLink]="['/app/new-file']">
      <mat-icon>add</mat-icon>
      Create New Value Sets
    </button>
  </mat-grid-tile>
</mat-grid-list>

<p>Edit Existing Value Sets:</p>
<mat-nav-list>
  <ng-container *ngFor="let csvFolder of csvFolders">
    <div mat-subheader>
      <mat-icon>folder</mat-icon> {{csvFolder.name}}
    </div>
    <mat-list-item *ngFor="let csvFile of csvFolder.files" (click)="onFileClick(csvFolder.name, csvFile)">
      <mat-icon>text_snippet</mat-icon>
      {{csvFile}}
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>
</mat-nav-list>
