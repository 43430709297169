import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const WORK_STATE_ROOT_PATH = 'work-state/';

@Injectable({
  providedIn: 'root'
})
export class WorkStateService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

  get(component: string, key: string): string {
    return this.storage.get(this.getPath(component, key)) as string;
  }

  set(component: string, key: string, value: string) {
    this.storage.set(this.getPath(component, key), value);
  }

  private getPath(component: string, key: string): string {
    return `${WORK_STATE_ROOT_PATH}${component}/${key}`;
  }
}
