<h3>Fix Errors for {{fileName}} ({{errorIndex + 1}} of {{numberOfErrors}})</h3>
<mat-progress-bar mode="determinate" value="{{progress}}"></mat-progress-bar>

<div *ngIf="errorBatch?.length">

  <h4>{{errorBatch[0].message}}</h4>

  <h4>In value sets</h4>
  <table mat-table [dataSource]="errorBatch">
    <ng-container matColumnDef="scope">
      <th mat-header-cell *matHeaderCellDef>Scope</th>
      <td mat-cell *matCellDef="let element" class="meta">{{element.record.Scope}}</td>
    </ng-container>

    <ng-container matColumnDef="valueSet">
      <th mat-header-cell *matHeaderCellDef>ValueSet</th>
      <td mat-cell *matCellDef="let element" class="meta">{{element.record.ValueSet}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['scope', 'valueSet']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['scope', 'valueSet'];"></tr>
  </table>

  <table class="mat-table">
    <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">
        </th>
        <th class="mat-header-cell">System</th>
        <th class="mat-header-cell">Code</th>
        <th class="mat-header-cell">Display</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mat-row">
        <td class="mat-cell">
          <mat-icon color="warn">error</mat-icon>
        </td>
        <td class="mat-cell">{{errorBatch[0].record.System}}</td>
        <td class="mat-cell">{{errorBatch[0].record.Code}}</td>
        <td class="mat-cell">{{errorBatch[0].record.Name}}</td>
      </tr>
    </tbody>
  </table>
</div>

<h4>Possible Fixes</h4>

<div class="fix-controls">
  <button (click)="removeMembers()" mat-flat-button color="warn">Remove Member</button>
  <app-code-system-selector [codeSystems]="codeSystems$ | async" [codeSystem]="codeSystem$ | async"
    (codeSystemChange)="onCodeSystemChange($event)">
  </app-code-system-selector>
  <mat-form-field>
    <input matInput placeholder="Search Code" #searchCodeInput [(ngModel)]="searchCode"
      (ngModelChange)="searchCodeChanged($event)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Search Display" #searchDisplayInput [(ngModel)]="searchDisplay"
      (ngModelChange)="searchDisplayChanged($event)">
  </mat-form-field>
</div>

<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="meta">
    <th mat-header-cell *matHeaderCellDef class="meta">
      Accept
    </th>
    <td mat-cell *matCellDef="let element" class="meta">
      <button mat-icon-button *ngIf="!element.isMember" title="Accept this as a fix" color="primary">
        <mat-icon>check_circle</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>Code</th>
    <td mat-cell *matCellDef="let element"><code>{{element.code}}</code></td>
  </ng-container>

  <ng-container matColumnDef="display">
    <th mat-header-cell *matHeaderCellDef>Display</th>
    <td mat-cell *matCellDef="let element">
      <span *ngFor="let phrase of element.display" [class.highlight]="phrase.inSource">{{phrase.phrase}} </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['meta', 'code', 'display']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['meta', 'code', 'display'];" class="action" (click)="acceptFix(row)"></tr>
</table>

<div *ngIf="(dataSource.searchCount$ | async) == 0" class="not-found">
  No codings found
</div>

<mat-paginator hidePageSize showFirstLastButtons [length]="dataSource.searchCount$ | async" [pageSize]="pageSize"
  [disabled]="dataSource.loading$ | async">
</mat-paginator>
