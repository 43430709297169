import { DataSource } from '@angular/cdk/table';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { RosettaService } from '../rosetta.service';
import { finalize } from 'rxjs/operators';
import { PossibleFixViewModel } from './possible-fix.view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class FixErrorsDataSource implements DataSource<PossibleFixViewModel>
{
  private possibleFixesSubject = new BehaviorSubject<PossibleFixViewModel[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private searchCountSubject = new ReplaySubject<number>(1);

  public codings$ = this.possibleFixesSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();
  public searchCount$ = this.searchCountSubject.asObservable();

  constructor(private rosetta: RosettaService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<PossibleFixViewModel[]> {
    return this.possibleFixesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.possibleFixesSubject.complete();
    this.loadingSubject.complete();
  }

  load(systemId: string, pageIndex: number, pageSize: number, codeSearch: string, displaySearch: string, csvDisplay: string) {
    const sourceWords = new Set<string>(csvDisplay.toLowerCase().split(/\W/));
    this.loadingSubject.next(true);
    this.rosetta.searchForCodesAndDisplays(systemId, codeSearch, displaySearch, pageIndex + 1, pageSize).pipe(
      finalize(() => this.loadingSubject.next(false))
    ).subscribe(page => {
      this.possibleFixesSubject.next(page.codings.map(c => {
        const vm = new PossibleFixViewModel(c, sourceWords);
        return vm;
      }));
      this.searchCountSubject.next(page.searchTotal);
    });
  }
}
