import { Inject, Injectable, InjectionToken } from '@angular/core';
import { LOCAL_STORAGE, StorageService, StorageTranscoder } from 'ngx-webstorage-service';
import { RosettaFileEntry } from './rosetta-file-entry';
import { v4 as uuidv4 } from 'uuid';
import { RosettaFileBody } from './rosetta-file-body';

const SCHEMA_VERSION = '1';
const FILE_LISTING_KEY = SCHEMA_VERSION + '/file-listing';
const FILE_BODY_KEY_PREFIX = SCHEMA_VERSION + '/file/';
const LAST_SAVED_KEY = SCHEMA_VERSION + '/last';

@Injectable({
  providedIn: 'root'
})
export class FileRepositoryService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {

  }

  private loadListing(): RosettaFileEntry[] {
    if (!this.storage.has(FILE_LISTING_KEY)) {
      this.saveListing([]);
    }
    return this.storage.get(FILE_LISTING_KEY) as RosettaFileEntry[];
  }

  private saveListing(entries: RosettaFileEntry[]) {
    this.storage.set(FILE_LISTING_KEY, entries);
  }

  public getLatestEntry(): RosettaFileEntry {
    const entries = this.loadListing();

    if (!entries || entries.length === 0) {
      return null;
    }

    const key = this.storage.get(LAST_SAVED_KEY);

    if (!key) {
      return null;
    }

    return entries.find(e => e.key === key);
  }

  public saveEntry(entry: RosettaFileEntry, body: RosettaFileBody) {
    const entries = this.loadListing();
    for (let i = 0; i < entries.length; i++) {
      if (entries[i].key === entry.key) {
        entries[i] = entry;
        this.saveListing(entries);
        this.storeBody(entry.key, body);
        this.storage.set(LAST_SAVED_KEY, entry.key);
        return;
      }
    }
    throw Error('Tried to save RosettaFileEntry with key ' + entry.key + ' not present in repository.');
  }

  public list(): Array<RosettaFileEntry> {
    return this.loadListing();
  }

  public create(): RosettaFileEntry {
    const entry: RosettaFileEntry = {
      key: uuidv4(),
      folder: null,
      name: null
    };
    const entries = this.loadListing();
    entries.push(entry);
    this.saveListing(entries);
    return entry;
  }

  public retrieveBody(uuid: string): RosettaFileBody {
    if (!this.storage.has(FILE_BODY_KEY_PREFIX + uuid)) {
      this.storeBody(uuid, {});
    }
    return this.storage.get(FILE_BODY_KEY_PREFIX + uuid) as RosettaFileBody;
  }

  public storeBody(uuid: string, body: RosettaFileBody) {
    try {
      this.storage.set(FILE_BODY_KEY_PREFIX + uuid, body);
    } catch
    {
      console.log('could not store file in local storage');
    }
  }
}

